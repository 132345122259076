import './RelatedLinks.css';

import { Box } from '@chakra-ui/react';
import React from 'react';

import useScript from '../../hooks/useScript';

const RelatedLinks: React.FC = () => {
  /*
  BrightEdge Autopilot Related Links SDK - BE recommends not loading thru GTM.
  We also need to ensure the script re-inits on Gatsby route changes, otherwise every page will show the links from the initial page load.
  */
  useScript('//cdn.bc0a.com/autopilot/f00000000059428/autopilot_sdk.js');

  return (
    <Box as="div" width="full" className="be-ix-link-block">
      {/* <div className="be-ix-link-block">
        <div className="be-related-link-container">
          <div className="be-label">Also of Interest</div>
          <ul className="be-list">
            <li className="be-list-item">
              <a
                className="be-related-link"
                href="https://newsroom.woodmenlife.org/be-on-the-lookout-for-potential-scams/"
              >
                Be on the Lookout for Cyber Scams
              </a>
            </li>
            <li className="be-list-item">
              <a
                className="be-related-link"
                href="https://www.woodmenlife.org/retirement/variable-annuities/"
              >
                Grow Your Retirement Funds with a Variable Annuity
              </a>
            </li>
            <li className="be-list-item">
              <a
                className="be-related-link"
                href="https://www.woodmenlife.org/extras/first-responders/"
              >
                Life Insurance for First Responders
              </a>
            </li>
          </ul>{' '}
        </div>
      </div> */}
      {/*BrightEdge Autopilot "Related Links" target DIV*/}
    </Box>
  );
};

export default RelatedLinks;
