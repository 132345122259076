import React, { Suspense } from 'react';

import {
  HomepageTemplateFragment,
  PageTemplateFragment,
} from '../../../graphql-types';
const AboutTemplate = React.lazy(() => import('./About'));
const AboutTemplateV2 = React.lazy(() => import('./AboutV2'));
const CarouselTemplate = React.lazy(() => import('./Carousel'));
const CarouselTemplateV2 = React.lazy(() => import('./CarouselV2'));
const CenterColumnTemplate = React.lazy(() => import('./CenterColumn'));
const DefaultTemplate = React.lazy(() => import('./DefaultTemplate'));
const DetailsTemplate = React.lazy(() => import('./Details'));
const ExtrasTemplate = React.lazy(() => import('./Extras'));
const FeaturedLinksTemplate = React.lazy(() => import('./FeaturedLinks'));
const HomepageTemplate = React.lazy(() => import('./Homepage'));
const LandingPageTemplate = React.lazy(() => import('./LandingPage'));
const LandingPageTemplateV2 = React.lazy(() => import('./LandingPageV2'));
const LifeInsurancePageTemplate = React.lazy(() => import('./LifeInsurance'));
const SearchResultsTemplate = React.lazy(() => import('./SearchResults'));
const SelectContentTemplate = React.lazy(() => import('./SelectContent'));
const SidebarTemplate = React.lazy(() => import('./Sidebar'));
const SidebarTemplateV2 = React.lazy(() => import('./SidebarV2'));
const SubjectBlocksTemplate = React.lazy(() => import('./SubjectBlocks'));
const SubjectBlocksTemplateV2 = React.lazy(() => import('./SubjectBlocksV2'));
const TimelineTemplate = React.lazy(() => import('./Timeline'));
const TimelineTemplateV2 = React.lazy(() => import('./TimelineV2'));
interface TemplateRendererProps {
  template: PageTemplateFragment;
  path: string;
}

const TemplateRenderer: React.FC<TemplateRendererProps> = ({
  template,
  path,
}) => {
  if (!template) {
    return null;
  }

  switch (template.__typename) {
    case 'ContentfulTemplateDefault':
      return (
        <Suspense fallback={<></>}>
          <DefaultTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulDefaultTemplate':
      return (
        <Suspense fallback={<></>}>
          <DefaultTemplate data={template} path={path} />
        </Suspense>
      );

    case 'ContentfulTemplateAbout':
      return (
        <Suspense fallback={<></>}>
          <AboutTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateAboutV2':
      return (
        <Suspense fallback={<></>}>
          <AboutTemplateV2 data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateCarousel':
      return (
        <Suspense fallback={<></>}>
          <CarouselTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateCarouselV2':
      return (
        <Suspense fallback={<></>}>
          <CarouselTemplateV2 data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateCenterColumn':
      return (
        <Suspense fallback={<></>}>
          <CenterColumnTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateDetails':
      return (
        <Suspense fallback={<></>}>
          <DetailsTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateExtras':
      return (
        <Suspense fallback={<></>}>
          <ExtrasTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateFeaturedLinks':
      return (
        <Suspense fallback={<></>}>
          <FeaturedLinksTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateHomepage' as any: // Preview uses reversed value
      return (
        <Suspense fallback={<></>}>
          <HomepageTemplate data={template as HomepageTemplateFragment} />
        </Suspense>
      );
    // Preview uses this naming convention, find better solution in future THW 2/7/24
    case 'ContentfulHomepageTemplate' as any: // Preview uses reversed value
      return (
        <Suspense fallback={<></>}>
          <HomepageTemplate data={template as HomepageTemplateFragment} />
        </Suspense>
      );
    case 'ContentfulTemplateLandingPage':
      return (
        <Suspense fallback={<></>}>
          <LandingPageTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateLandingPageV2':
      return (
        <Suspense fallback={<></>}>
          <LandingPageTemplateV2 data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateLifeInsurance':
      return (
        <Suspense fallback={<></>}>
          <LifeInsurancePageTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateSearchResults':
      return (
        <Suspense fallback={<></>}>
          <SearchResultsTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateSidebar':
      return (
        <Suspense fallback={<></>}>
          <SidebarTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateSidebarV2':
      return (
        <Suspense fallback={<></>}>
          <SidebarTemplateV2 data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateSelectContent':
      return (
        <Suspense fallback={<></>}>
          <SelectContentTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateSubjectBlocks':
      return (
        <Suspense fallback={<></>}>
          <SubjectBlocksTemplate data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateSubjectBlocksV2':
      return (
        <Suspense fallback={<></>}>
          <SubjectBlocksTemplateV2 data={template} path={path} />
        </Suspense>
      );
    case 'ContentfulTemplateTimeline':
      return (
        <Suspense fallback={<></>}>
          <TimelineTemplate data={template} />
        </Suspense>
      );
    case 'ContentfulTemplateTimelineV2':
      return (
        <Suspense fallback={<></>}>
          <TimelineTemplateV2 data={template} />
        </Suspense>
      );
    default:
      console.warn(`Unhandled template type ${template.__typename}`);
      return null;
  }
};

export default TemplateRenderer;
