import { graphql, PageProps } from 'gatsby';
import React, { Suspense } from 'react';

import { PageByIdQuery } from '../../graphql-types';
import { SmartyProvider } from '../components/Geolocation/geolocation.context';
import Layout from '../components/Layout';
import PageIdentifier from '../components/PageIdentifier';
import TemplateRenderer from '../components/PageTemplates';
import RelatedLinks from '../components/RelatedLinks';
import { WLContainer } from '../design-library';
import { useLastUpdated } from '../hooks/hooks';
import ContentfulUtils from '../utils/contentful.utils';

const BreadcrumbAdapter = React.lazy(
  () => import('../components/BreadcrumbAdapter'),
);

interface PageTemplateProps extends PageProps<PageByIdQuery> {}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data,
  location,
  path,
}) => {
  const page = data.contentfulPage!;
  const lastUpdated = useLastUpdated(page, location.pathname);

  return (
    <Layout
      seo={{
        title: page.title || undefined,
        description:
          ContentfulUtils.getLongTextValue(page, 'description') || undefined,
        slug: page.slug || undefined,
        image: ContentfulUtils.getAssetInfo(page.image)?.url || undefined,
      }}
      currentPath={location.pathname}
    >
      {page.parentPage && (
        <WLContainer
          width="EXTRA_WIDE"
          align="start"
          paddingX={{ base: 4, lg: 8 }}
          paddingTop={{ base: 2, lg: 2 }}
          paddingBottom={{ base: 3, lg: 3 }}
        >
          <Suspense fallback={<></>}>
            <BreadcrumbAdapter
              currentPage={page.breadcrumbTitle}
              parent={page.parentPage}
            />
          </Suspense>
        </WLContainer>
      )}

      {page.template && (
        <SmartyProvider>
          <TemplateRenderer
            template={page.template}
            path={location.pathname}
            location={location}
          />
        </SmartyProvider>
      )}

      <RelatedLinks key={path} />

      <WLContainer width="WIDE" align="start" paddingX="19px">
        <PageIdentifier
          webNumber={page.webNumber || ''}
          secondaryWebNumber={page.secondaryWebNumber || ''}
          lastUpdated={lastUpdated}
        />
      </WLContainer>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...Page
    }
  }
`;
