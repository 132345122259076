import { useEffect } from 'react';

/**
 * Add a `script` element to the DOM to load a JS script.
 *
 * @param resourceUrl
 * @param delay (optional) If provided, wait to load the script until param is not `true`
 */
const useScript = (resourceUrl: string, delay?: boolean) => {
  useEffect(() => {
    if (delay === true) {
      return;
    }
    const script = document.createElement('script');
    script.async = false;
    script.src = resourceUrl;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, delay]);
};

export default useScript;
