import React from 'react';

import { PageFragment, PersonFragment } from '../../graphql-types';

const useLastUpdated = (data: PageFragment | PersonFragment, path: string) => {
  return React.useMemo(() => {
    if (!data.lastUpdated) {
      return '';
    }
    // `lastUpdated` comes back from Contentful as a `YYYY-MM-DD` value - we add a "zero" time value so that
    // it is parsed in the local timezone to preserve the "absolute" date. If we didn't do this the date would
    // be interpreted as UTC and would shift +- several hours which could change the reflected date
    const lastUpdatedDate = new Date(`${data.lastUpdated}T00:00:00`);

    if (path === '/' || path === '/insurance/') {
      // FIXME - Quick hack to force date to render as M/YY on homepage and insurance overview pages
      return `${lastUpdatedDate.getMonth() + 1}/${lastUpdatedDate
        .getFullYear()
        .toString()
        .substring(2)}`;
    } else if (path === '/landing/family-term-a/') {
      // FIXME - Quick hack to force date to render as M/YYYY on one landing page
      return `${
        lastUpdatedDate.getMonth() + 1
      }/${lastUpdatedDate.getFullYear()}`;
    }

    return lastUpdatedDate.toLocaleDateString('en-US'); // MM/DD/YYYY
  }, [data.lastUpdated, path]);
};

export { useLastUpdated };
